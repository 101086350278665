import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
export const SimpleCheckbox = ({ label, checked, onChange }) => {
    return (_jsxs("label", Object.assign({ class: "text-xs" }, { children: [_jsx("input", { type: "checkbox", checked: checked, onChange: () => {
                    onChange(!checked);
                } }), _jsx("span", Object.assign({ class: "text-xs" }, { children: label }))] })));
};
export const BackButton = ({ onClick }) => (_jsxs("button", Object.assign({ onClick: (ev) => {
        ev.stopPropagation();
        onClick();
    }, class: "flex text-xs items-center space-x-2 px-1 py-0.5 relative -left-1 rounded text-voiceCompassPurple hover:text-voiceCompassPurpleDarker hover:bg-voiceCompassPurple05" }, { children: [_jsx("svg", Object.assign({ width: "6", height: "10", viewBox: "0 0 6 10", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M4.8583 10L6 8.825L2.2915 5L6 1.175L4.8583 -9.98106e-08L4.37114e-07 5L4.8583 10Z", fill: "currentColor" }) })), _jsx("span", { children: "Back" })] })));
export const RemoveButton = ({ onClick, label, }) => (_jsx("button", Object.assign({ class: "text-xs text-red-600 hover:text-red-700 hover:bg-red-50 px-1 py-0.5 -left-1 rounded", onClick: () => {
        onClick();
    } }, { children: label || "Remove" })));
export const SimpleSelect = ({ label, value, options, onChange, }) => {
    return (_jsxs("div", Object.assign({ class: "text-xs" }, { children: [_jsxs("span", Object.assign({ class: "text-gray-600" }, { children: [label, ":"] })), " ", _jsx("select", Object.assign({ class: "bg-gray-100 px-1 text-gray-800 !cursor-pointer hover:text-voiceCompassPurple hover:bg-voiceCompassPurple05 rounded-lg", value: String(value), onChange: (ev) => {
                    onChange(ev.target.value);
                } }, { children: options.map((option) => (_jsx("option", Object.assign({ value: String(option.value) }, { children: option.label })))) }))] })));
};
export const ToggleButton = ({ label, onClick, isActive }) => (_jsx("button", Object.assign({ class: `rounded-lg px-2 text-xs border transition-colors ${isActive
        ? "text-voiceCompassPurple border-voiceCompassPurple bg-voiceCompassPurple05 text-white cursor-default"
        : "border-transparent bg-gray-100 hover:text-voiceCompassPurple hover:bg-voiceCompassPurple05"}`, onClick: onClick }, { children: label })));
export const Switch = (props) => {
    return (_jsxs("label", Object.assign({ className: "relative flex items-center group space-x-1" }, { children: [_jsx("input", { type: "checkbox", className: "sr-only peer", checked: props.checked, onChange: (ev) => {
                    props.onChange(ev.target.checked);
                } }), _jsx("span", { className: [
                    "flex items-center flex-shrink-0 w-6 h-3 p-0.5 rounded-full duration-200",
                    // Undo the effect of `space-x-2` on the parent since this is technically the first child (input is visually hidden)
                    "!ml-0",
                    "bg-gray-300",
                    "peer-checked:bg-voiceCompassPurple peer-disabled:opacity-50",
                    "after:w-2 after:h-2 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3",
                ].join(" ") }), _jsx("span", Object.assign({ className: "text-xs peer-disabled:text-black40" }, { children: props.label || "" }))] })));
};
