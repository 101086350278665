import { useState, useEffect, useCallback } from "preact/hooks";
const localStorageKey = "vc-position";
const setPosition = (position) => {
    localStorage.setItem(localStorageKey, JSON.stringify(position));
};
const getPosition = () => {
    try {
        const pos = JSON.parse(localStorage.getItem(localStorageKey) || "");
        if (Array.isArray(pos) && pos.length === 2) {
            return pos;
        }
        throw new Error("Invalid structure");
    }
    catch (err) {
        return [0, 0];
    }
};
export const useDrag = () => {
    const [d, setD] = useState({
        drag: null,
        position: getPosition(),
    });
    useEffect(() => {
        setPosition(d.position);
    }, [d.position]);
    const calcPosition = useCallback((currentD) => currentD.drag
        ? [
            currentD.position[0] +
                currentD.drag.current[0] -
                currentD.drag.start[0],
            currentD.position[1] +
                currentD.drag.current[1] -
                currentD.drag.start[1],
        ]
        : currentD.position, []);
    const isDragging = Boolean(d.drag);
    const onBodyMouseMove = useCallback((e) => {
        if (!isDragging)
            return;
        setD((prev) => prev.drag
            ? Object.assign(Object.assign({}, prev), { drag: Object.assign(Object.assign({}, prev.drag), { current: [e.clientX, e.clientY] }) }) : prev);
    }, [setD, isDragging]);
    const onMouseDown = useCallback((e) => {
        const pt = [e.clientX, e.clientY];
        setD((prev) => (Object.assign(Object.assign({}, prev), { drag: {
                start: pt,
                current: pt,
            } })));
    }, [setD]);
    const onBodyMouseUp = useCallback(() => {
        setD((prev) => (Object.assign(Object.assign({}, prev), { drag: null, position: calcPosition(prev) })));
    }, [setD]);
    useEffect(() => {
        document.body.addEventListener("mousemove", onBodyMouseMove);
        return () => {
            document.body.removeEventListener("mousemove", onBodyMouseMove);
        };
    }, [onBodyMouseMove]);
    useEffect(() => {
        document.body.addEventListener("mouseup", onBodyMouseUp);
        return () => {
            document.body.removeEventListener("mouseup", onBodyMouseUp);
        };
    }, [onBodyMouseUp]);
    return {
        position: calcPosition(d),
        onMouseDown,
    };
};
