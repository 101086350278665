import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "preact/jsx-runtime";
import { useMemo, useEffect, useCallback, } from "preact/hooks";
import { BackButton, RemoveButton, SimpleSelect, ToggleButton } from "../ui";
import { getLinks, toSelector } from "../logic";
import { TriggerIcon, RemoveCircleOutlineIcon } from "../icons";
import { LinkEditor } from "./LinkEditor";
import { MatchCounter } from "./MatchCounter";
import { SpeechSynthesis } from "./SpeechSynthesis";
const eventOptions = [
    { value: "click", label: "Click" },
    { value: "invalid", label: "Invalid form input" },
    { value: "start", label: "Journey start" },
    { value: "inserted", label: "Appear on page" },
];
const isInsideComponent = (element) => {
    const containerWithCustomElementWrapper = document.querySelector("point-and-click");
    return Boolean(containerWithCustomElementWrapper &&
        (containerWithCustomElementWrapper.contains(element) ||
            containerWithCustomElementWrapper === element));
};
export const StepEditor = ({ step, setStep, onBackButtonClick, getParentBound, apiKey, token, dev }) => {
    const basedOn = useMemo(() => { var _a; return (typeof ((_a = step.trigger) === null || _a === void 0 ? void 0 : _a.selector) === "string" ? "css" : "html"); }, [step.trigger]);
    const updateCssSelector = (e) => {
        const selector = e.target.value;
        setStep((prev) => prev && Object.assign(Object.assign({}, prev), { trigger: prev.trigger && Object.assign(Object.assign({}, prev.trigger), { selector }) }));
    };
    useEffect(() => {
        const styleTag = document.createElement("style");
        styleTag.innerText = `
    [data-vc-hovered] {
      outline: 1px solid #60a5fa;
      outline-offset: 2px;
    }
    [data-vc-active] {
      outline: 1px solid #fbbf24;
      outline-offset: 2px;
    }
    `;
        document.head.appendChild(styleTag);
        return () => {
            document.head.removeChild(styleTag);
        };
    }, []);
    const currentSelector = useMemo(() => {
        var _a, _b, _c, _d;
        return ((_d = (_b = (_a = step.trigger) === null || _a === void 0 ? void 0 : _a.selector) !== null && _b !== void 0 ? _b : (((_c = step.trigger) === null || _c === void 0 ? void 0 : _c.path) && toSelector(step.trigger.path))) !== null && _d !== void 0 ? _d : "");
    }, [step.trigger]);
    useEffect(() => {
        if (!currentSelector) {
            return;
        }
        try {
            const elements = document.querySelectorAll(currentSelector);
            elements.forEach((element) => {
                element.setAttribute("data-vc-active", "true");
            });
            return () => {
                elements.forEach((element) => {
                    element.removeAttribute("data-vc-active");
                });
            };
        }
        catch (err) {
            console.warn(err);
            return;
        }
    }, [currentSelector]);
    const handleBodyClick = useCallback((ev) => {
        if (isInsideComponent(ev.target)) {
            return;
        }
        ev.preventDefault();
        ev.stopPropagation();
        setStep((prev) => {
            return (prev && Object.assign(Object.assign({}, prev), { trigger: prev.trigger
                    ? Object.assign(Object.assign({}, prev.trigger), { path: getLinks(ev.target) }) : {
                    event: "click",
                    path: getLinks(ev.target),
                } }));
        });
    }, [setStep]);
    const handleMouseOver = useCallback((ev) => {
        if (isInsideComponent(ev.target)) {
            return;
        }
        const element = ev.target;
        element.setAttribute("data-vc-hovered", "true");
    }, []);
    const handleMouseOut = useCallback((ev) => {
        const element = ev.target;
        element.removeAttribute("data-vc-hovered");
    }, []);
    useEffect(() => {
        document.body.addEventListener("click", handleBodyClick, true);
        document.body.addEventListener("mouseover", handleMouseOver);
        document.body.addEventListener("mouseout", handleMouseOut);
        return () => {
            document.body.removeEventListener("click", handleBodyClick, true);
            document.body.removeEventListener("mouseover", handleMouseOver);
            document.body.removeEventListener("mouseout", handleMouseOut);
        };
    }, [handleBodyClick, handleMouseOver, handleMouseOut]);
    const modifyTrigger = (fn) => {
        setStep((prev) => prev && Object.assign(Object.assign({}, prev), { trigger: fn(prev.trigger) }));
    };
    return (_jsxs("div", Object.assign({ class: "space-y-2" }, { children: [onBackButtonClick && _jsx(BackButton, { onClick: onBackButtonClick }), _jsxs("div", Object.assign({ class: "!mt-1 flex items-center justify-between" }, { children: [_jsxs("div", { children: [_jsx("input", Object.assign({ class: "font-medium text-sm py-0.5 focus:outline-blue-300 outline-offset-2", placeholder: "Enter name", value: step.name, onInput: (ev) => {
                                    setStep((prev) => prev && Object.assign(Object.assign({}, prev), { name: ev.target.value }));
                                } }, { children: step.name })), _jsx("p", Object.assign({ class: "mt-0.5 font-mono text-xs text-gray-500" }, { children: step.key }))] }), step.body && (_jsx(SpeechSynthesis, { languageCode: /* TODO: handle languages dynamically */ "en-US", transcript: step.body, apiKey: apiKey, token: token, dev: dev }))] })), _jsx("hr", {}), _jsxs("div", Object.assign({ class: "space-y-2" }, { children: [_jsxs("p", Object.assign({ class: "text-sm font-medium flex items-center space-x-1" }, { children: [_jsx("span", Object.assign({ class: "w-4 h-4 inline-block" }, { children: _jsx(TriggerIcon, {}) })), _jsx("span", { children: "Trigger" }), !step.trigger && (_jsx("span", Object.assign({ class: "text-gray-500 px-2 block !ml-2 uppercase text-[10px] bg-gray-100 rounded" }, { children: "not set" })))] })), step.trigger ? (_jsxs("div", Object.assign({ class: "space-y-2" }, { children: [_jsx(SimpleSelect, { label: "Event", value: step.trigger.event, options: eventOptions, onChange: (ev) => {
                                    setStep((prev) => prev && Object.assign(Object.assign({}, prev), { trigger: prev.trigger && Object.assign(Object.assign({}, prev.trigger), { event: ev }) }));
                                } }), step.trigger.event !== "start" && (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ class: "flex flex-wrap space-x-1 space-y-1" }, { children: [_jsx("span", Object.assign({ class: "text-xs text-gray-600 mt-1" }, { children: "Base on:" })), _jsx(ToggleButton, { isActive: basedOn === "html", label: "Direct HTML selection", onClick: () => {
                                                    modifyTrigger((prev) => prev && Object.assign(Object.assign({}, prev), { selector: undefined }));
                                                } }), _jsx("span", Object.assign({ class: "text-xs text-gray-600 mt-1" }, { children: "or" })), _jsx(ToggleButton, { isActive: basedOn === "css", label: "Custom CSS selector", onClick: () => {
                                                    modifyTrigger((prev) => prev && Object.assign(Object.assign({}, prev), { selector: "" }));
                                                } })] })), typeof step.trigger.selector === "string" ? (_jsx("div", { children: _jsx("input", { type: "text", class: "border-b pt-0.5 pb-px mt-2 font-mono block w-full text-xs border-gray-300 focus:outline-0 focus:border-voiceCompassPurpleDarker", placeholder: "Enter selector", value: step.trigger.selector, onInput: updateCssSelector }) })) : (_jsxs("div", Object.assign({ class: "flex flex-wrap space-x-1 space-y-1" }, { children: [_jsx("span", Object.assign({ class: "text-xs text-gray-600 self-end" }, { children: "Path:" })), step.trigger.path ? (_jsxs(_Fragment, { children: [step.trigger.path.map((link, index) => {
                                                        return (_jsx(LinkEditor, { value: link, getParentBound: getParentBound, onChange: (newLink) => {
                                                                setStep((prev) => prev && Object.assign(Object.assign({}, prev), { trigger: prev.trigger && Object.assign(Object.assign({}, prev.trigger), { path: (prev.trigger.path || []).map((link, i) => i === index ? newLink : link) }) }));
                                                            } }, index));
                                                    }), _jsx("button", Object.assign({ className: "inline-block w-4 h-4 p-[1px] text-gray-500 hover:text-red-600", title: "Remove", onClick: () => {
                                                            modifyTrigger((prev) => prev && Object.assign(Object.assign({}, prev), { path: undefined }));
                                                        } }, { children: _jsx(RemoveCircleOutlineIcon, {}) }))] })) : (_jsx("p", Object.assign({ className: "text-xs text-gray-400" }, { children: "Select an element on the page." })))] }))), currentSelector && _jsx(MatchCounter, { selector: currentSelector })] })), _jsx(RemoveButton, { label: "Remove trigger", onClick: () => {
                                    modifyTrigger(() => undefined);
                                } })] }))) : (_jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("p", Object.assign({ class: "text-gray-600 text-xs" }, { children: "Triggers define the user interaction that will trigger this step during the journey." })), _jsx(ToggleButton, { onClick: () => {
                                    modifyTrigger(() => ({
                                        event: "click",
                                    }));
                                }, label: "Set trigger", isActive: false })] })))] }))] })));
};
