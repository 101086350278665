var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { personas } from "./config";
const baseUrl = (dev) => `https://${dev ? "dev." : ""}journeys.voicecompass.ai/v1`;
export const updateSteps = ({ journeyId, token, steps, apiKey, dev, }) => {
    return fetch(`${baseUrl(dev)}/journeyTriggers/${journeyId}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-api-key": apiKey,
        },
        body: JSON.stringify({ steps }),
    })
        .then((res) => res.json())
        .then((res) => res.steps);
};
export const fetchSteps = ({ journeyId, token, apiKey, dev, }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const res = yield fetch(`${baseUrl(dev)}/journeyTriggers/${journeyId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "x-api-key": apiKey,
            },
        });
        const json = yield res.json();
        return json.steps;
    }
    catch (err) {
        return null;
    }
});
const speechSynthesisCache = {};
export const getSpeechSynthesisCache = (props) => {
    const language = props.languageCode || "en-US";
    const cacheKey = `vcsdk-${language}-${props.transcript}`;
    const cached = speechSynthesisCache[cacheKey];
    if (cached) {
        return cached;
    }
    return null;
};
export const setSpeechSynthesisCache = (props, base64Encoded) => {
    const language = props.languageCode || "en-US";
    const cacheKey = `vcsdk-${language}-${props.transcript}`;
    speechSynthesisCache[cacheKey] = base64Encoded;
};
export const fetchSpeechSynthesis = (data) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const language = data.languageCode || "en-US";
        const cached = getSpeechSynthesisCache(data);
        if (cached) {
            return Promise.resolve(cached);
        }
        const res = yield fetch(`${baseUrl(data.dev)}/speechSynthesis`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${data.token}`,
                "x-api-key": data.apiKey,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                text: data.transcript,
                persona: data.persona ||
                    ((_a = personas.find((p) => p.defaultLanguage === language)) === null || _a === void 0 ? void 0 : _a.value),
                language,
            }),
        });
        const base64Audio = yield res.json();
        if (typeof base64Audio !== "string") {
            throw new Error("Invalid transcript");
        }
        const url = `data:audio/mp3;base64,${base64Audio}`;
        setSpeechSynthesisCache(data, url);
        return url;
    }
    catch (err) {
        return null;
    }
});
