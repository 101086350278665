import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "preact/jsx-runtime";
import { useRef, useState, useEffect } from "preact/hooks";
import { Switch } from "../ui";
export const LinkEditor = ({ value, onChange, getParentBound }) => {
    const containerRef = useRef(null);
    const [isTooRight, setIsTooRight] = useState(false);
    const [isTooTop, setIsTooTop] = useState(false);
    const [isDropdownClosed, setIsDropdownClosed] = useState(true);
    useEffect(() => {
        var _a, _b;
        if (isDropdownClosed)
            (_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.removeAttribute("open");
        else
            (_b = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _b === void 0 ? void 0 : _b.setAttribute("open", "open");
    }, [isDropdownClosed]);
    const handleToggle = (ev) => {
        var _a;
        const isOpen = ((_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.getAttribute("open")) !== null;
        if (isOpen)
            setIsDropdownClosed(false);
        else
            setIsDropdownClosed(true);
        const parentBounding = getParentBound();
        const dropdown = ev.target.querySelector("div");
        const dropdownBounding = dropdown.getBoundingClientRect();
        if (dropdownBounding.right > parentBounding.right - 20) {
            // 20 is just for savety, scrollbars, other things
            setIsTooRight(true);
        }
        if (dropdownBounding.top < parentBounding.top + 50) {
            // todo
            // 50 includes 20 for safety plus current height of the "top bar", should be a variable also
            setIsTooTop(true);
        }
    };
    return (_jsxs("details", Object.assign({ class: "cursor-pointer open:drop-shadow-lg", ref: containerRef, onToggle: handleToggle }, { children: [_jsx("summary", Object.assign({ class: "list-none bg-gray-100 hover:text-voiceCompassPurpleDarker hover:bg-voiceCompassPurple05 rounded-lg px-2 text-xs", onBlur: () => { setIsDropdownClosed(true); } }, { children: value.tagName.value })), _jsxs("div", Object.assign({ class: `text-left w-40 absolute -top-1 rounded-lg z-20 transform -translate-y-full bg-white animate-firstlyTransparent ${isTooRight && "right-0"} ${isTooTop && "translate-y-1/3"}`, tabIndex: 0, onfocusout: (ev) => {
                    if (!ev.currentTarget.contains(ev.relatedTarget)) {
                        setIsDropdownClosed(true);
                    }
                }, onFocus: () => { setIsDropdownClosed(false); } }, { children: [_jsx("div", Object.assign({ class: "p-1" }, { children: _jsx(Switch, { label: "Enabled", checked: value.enabled, onChange: (val) => {
                                onChange(Object.assign(Object.assign({}, value), { enabled: val }));
                            } }) })), value.enabled && (_jsxs(_Fragment, { children: [_jsx("hr", {}), _jsx("div", Object.assign({ class: "p-1" }, { children: _jsx(Switch, { label: _jsxs(_Fragment, { children: ["Tag name ", _jsx("span", Object.assign({ className: "text-gray-400" }, { children: value.tagName.value }))] }), checked: value.tagName.enabled, onChange: (val) => {
                                        onChange(Object.assign(Object.assign({}, value), { tagName: Object.assign(Object.assign({}, value.tagName), { enabled: val }) }));
                                    } }) })), value.id && (_jsx("div", Object.assign({ class: "p-1" }, { children: _jsx(Switch, { label: _jsxs(_Fragment, { children: ["ID ", _jsx("span", Object.assign({ className: "text-gray-400" }, { children: value.id.value }))] }), checked: value.id.enabled, onChange: (val) => {
                                        onChange(Object.assign(Object.assign({}, value), { id: value.id && Object.assign(Object.assign({}, value.id), { enabled: val }) }));
                                    } }) }))), value.index && (_jsx("div", Object.assign({ class: "p-1" }, { children: _jsx(Switch, { label: _jsxs(_Fragment, { children: ["Order ", _jsx("span", Object.assign({ className: "text-gray-400" }, { children: value.index.value }))] }), checked: value.index.enabled, onChange: (val) => {
                                        onChange(Object.assign(Object.assign({}, value), { index: value.index && Object.assign(Object.assign({}, value.index), { enabled: val }) }));
                                    } }) })))] }))] }))] })));
};
