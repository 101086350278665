import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { useState } from "preact/hooks";
import { fetchSpeechSynthesis } from "../api";
const Play = () => (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, { children: _jsx("path", { fill: "currentColor", d: "M8 5v14l11-7z" }) })));
const Close = () => (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, { children: _jsx("path", { fill: "currentColor", d: "M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" }) })));
const Pause = () => (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, { children: _jsx("path", { fill: "currentColor", d: "M6 19h4V5H6v14zm8-14v14h4V5h-4z" }) })));
export const SpeechSynthesis = (props) => {
    const [state, setState] = useState({
        type: "rest",
    });
    const handleAudioEnd = () => {
        setState({ type: "rest" });
    };
    const [error, setError] = useState(false);
    const handleClick = () => {
        if (state.type === "playing") {
            state.audio.pause();
            setState({ type: "rest" });
            return;
        }
        setState({ type: "fetching" });
        fetchSpeechSynthesis(Object.assign({}, props))
            .then((audioUrl) => {
            if (!audioUrl) {
                throw new Error("Invalid audio URL");
            }
            const audio = new Audio(audioUrl);
            audio.addEventListener("ended", handleAudioEnd, {
                once: true,
            });
            audio.play();
            setState({ type: "playing", audio: audio });
        })
            .catch((err) => {
            setState({ type: "rest" });
            setError(true);
        });
    };
    return (_jsxs("div", Object.assign({ className: "w-4 h-4 inline-block relative" }, { children: [_jsx("button", Object.assign({ className: "w-full h-full inline-flex flex-none items-center justify-center p-0.5 text-white bg-voiceCompassPurple hover:bg-voiceCompassPurpleDarker disabled:hover:bg-voiceCompassPurple rounded-full disabled:opacity-50", onClick: handleClick, disabled: state.type === "fetching" }, { children: state.type === "playing" ? (_jsx(Pause, {})) : state.type === "fetching" ? (_jsx(Play, {})) : (_jsx(Play, {})) })), error && (_jsxs("div", Object.assign({ className: "absolute flex space-x-2 -bottom-2 w-40 right-0 p-1 rounded-lg transform translate-y-full shadow-md bg-white text-redMain text-xs" }, { children: [_jsxs("p", { children: ["Could not generate transcript audio. Please make sure you are using", " ", _jsx("a", Object.assign({ className: "underline", href: "https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html#break-tag" }, { children: "valid SSML" })), " ", "and you are not wrapping your transcript in a", " ", _jsx("span", Object.assign({ className: "font-mono" }, { children: "<speak></speak>" })), " tag ."] }), _jsx("button", Object.assign({ className: "w-4 h-4 inline-block flex-none hover:bg-redFaint hover:text-redDarker rounded", onClick: () => {
                            setError(false);
                        }, title: "Close" }, { children: _jsx(Close, {}) }))] })))] })));
};
