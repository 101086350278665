export const toSelector = (links) => {
    return links
        .map((link) => {
        if (!link.enabled) {
            return "*";
        }
        return [
            link.tagName.enabled ? link.tagName.value : "*",
            link.id && link.id.enabled ? `#${link.id.value}` : undefined,
            ...link.classList
                .filter((val) => val.enabled)
                .map((val) => `.${val.value}`),
            link.index && link.index.enabled
                ? `:nth-child(${link.index.value + 1})`
                : undefined,
        ]
            .filter((val) => Boolean(val))
            .join("");
    })
        .join(" > ");
};
export const isInsideComponent = (element) => {
    const containerWithCustomElementWrapper = document.querySelector("point-and-click");
    return Boolean(containerWithCustomElementWrapper &&
        (containerWithCustomElementWrapper.contains(element) ||
            containerWithCustomElementWrapper === element));
};
export const getLinks = (elem) => {
    let path = [];
    let current = elem;
    while (current) {
        path = [
            {
                enabled: true,
                tagName: { value: current.tagName.toLowerCase(), enabled: true },
                classList: (current.classList
                    ? Array.from(current.classList) || []
                    : []).map((cls) => ({ value: cls, enabled: true })),
                index: current.parentNode
                    ? {
                        value: Array.from(current.parentNode.children).indexOf(current),
                        enabled: true,
                    }
                    : undefined,
                id: current.id && current.id !== ""
                    ? { value: current.id, enabled: true }
                    : undefined,
            },
            ...path,
        ];
        if (current === document.body) {
            current = null;
        }
        current = (current === null || current === void 0 ? void 0 : current.parentNode) || null;
    }
    return path;
};
