const sessionStorageKey = "voicecompass-session";
export const saveSession = (session) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify({
        savedAt: new Date().getTime(),
        session,
    }));
};
export const clearSession = () => {
    sessionStorage.removeItem(sessionStorageKey);
};
export const retrieveSession = (contactId) => {
    try {
        const data = JSON.parse(sessionStorage.getItem(sessionStorageKey) || "");
        const session = data === null || data === void 0 ? void 0 : data.session;
        const savedAt = data === null || data === void 0 ? void 0 : data.savedAt;
        if (session &&
            // If a contact ID is available, invalidate the session in case it has since changed
            (!contactId || session.contactId === contactId) &&
            savedAt &&
            new Date().getTime() - savedAt < 15 * 60 * 1000) {
            return session;
        }
    }
    catch (err) {
        return null;
    }
    return null;
};
