import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { useState, useEffect } from "preact/hooks";
export const MatchCounter = ({ selector }) => {
    const [numberOfElementsFound, setNumberOfElementsFound] = useState(0);
    useEffect(() => {
        try {
            const elems = document.querySelectorAll(selector);
            setNumberOfElementsFound(elems.length);
        }
        catch (e) {
            setNumberOfElementsFound(0);
        }
    }, [selector]);
    return (_jsxs("p", Object.assign({ class: "text-gray-400 text-xs flex items-center space-x-1" }, { children: [_jsx("span", { class: `w-1 h-1 inline-block rounded-full ${numberOfElementsFound === 1 ? "bg-green-500" : "bg-amber-500"}` }), _jsxs("span", { children: [numberOfElementsFound, " element", numberOfElementsFound !== 1 && "s", " found for this selector"] })] })));
};
